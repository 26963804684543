import { Button, Divider, InputGroup, Section, Tag, TagInput, TextArea } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IGetRecordById,
} from '../../../../../../../core/records/store/actions';
import { httpPost } from '../../../../../../../shared/http/requests';
import { MY_CASES_ADD_EMAIL, MY_CASES_TOGGLE_EMAIL_EDITOR } from '../../store/constants';
import './styles.scss';

interface Props {
  createRecord: (params: ICreateRecords, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  userReducer: any;
}

const MyCasesEmailEditorWidget: React.FC<Props> = (props: Props) => {
  const { createRecord, getRecordById, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCase } = state;

  const [newEmailBody, setNewEmailBody] = useState<string>('');
  const [newEmailSubject, setNewEmailSubject] = useState<string>('');
  const [isCCEnabled, setIsCCEnabled] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
  const [CCEmails, setCCEmails] = useState<string[]>([]);

  const toggleEmailEditor = () => {
    dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: null });
    setNewEmailBody('');
    setNewEmailSubject('');
    setValidationErrorMessage('');
    setIsCCEnabled(false);
    setIsSendingEmail(false);
    setCCEmails([]);
  };

  const validateEmail = () => {
    setValidationErrorMessage('');

    if (isEmailSubjectEmpty()) {
      setValidationErrorMessage('Subject is required');
    } else if (isEmailBodyEmpty()) {
      setValidationErrorMessage('Email body is required');
    } else {
      sendEmail();
    }
  };

  const smoothScrollFeedToBottom = () => {
    const feedContainer = state.feedContainerRef?.current;
    if (feedContainer) {
      feedContainer.scrollTo({
        top: feedContainer.scrollTop + feedContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendEmail = async () => {
    try {
      setIsSendingEmail(true);

      const res = await httpPost(`NotificationModule/v1.0/email/send`, {
        from: userReducer?.user?.email,
        to: getProperty(state.selectedCaseContact, 'EmailAddress'),
        cc: CCEmails.join(','),
        subject: newEmailSubject,
        textMessage: newEmailBody,
        htmlMessage: newEmailBody,
        caseId: selectedCase?.id,
      });

      setIsSendingEmail(false);
      toggleEmailEditor();

      const emailRecord = res.data?.data;
      if (emailRecord) {
        dispatch({ type: MY_CASES_ADD_EMAIL, payload: emailRecord });
      }

      setTimeout(() => {
        smoothScrollFeedToBottom();
      }, 400);
    } catch (error) {
      setIsSendingEmail(false);
    }
  };

  const isEmailBodyEmpty = () => {
    return newEmailBody.trim() === '';
  };

  const isEmailSubjectEmpty = () => {
    return newEmailSubject.trim() === '';
  };

  return (
    <>
      {/* Add Internal Comment Button */}

      <Button
        text="Email Customer"
        disabled={state.isAddingNote || state.isComposingEmail}
        outlined
        onClick={() => dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: true })}
        intent="primary"
        style={{
          boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.10)',
          marginRight: 10,
          borderRadius: 5,
        }}
      />

      {/* Internal Comment editor */}
      {state.isComposingEmail && (
        <div className="newEmailContainer">
          <Section
            compact
            className="newEmailSection"
            title="Compose Email"
            icon="envelope"
            rightElement={<Button minimal icon="cross" onClick={toggleEmailEditor} />}
          >
            <div
              style={{
                background: 'white',
                height: 'calc(100vh - 593px)',
                overflowY: 'auto',
                padding: 8,
              }}
            >
              <Row align="middle" justify="space-between">
                {/* To: */}

                <Col span={1}>
                  <span style={{ color: '#606B85' }}>To: </span>
                </Col>
                <Col span={20} style={{ paddingLeft: 5 }}>
                  <Tag minimal intent="primary" style={{ borderRadius: 5 }}>
                    {state.selectedCaseContact?.title} (
                    {getProperty(state.selectedCaseContact, 'EmailAddress')})
                  </Tag>
                </Col>

                <Col span={3} style={{ textAlign: 'right' }}>
                  <Button
                    text="Cc"
                    minimal={!isCCEnabled}
                    small
                    intent="primary"
                    style={{ borderRadius: 5 }}
                    onClick={() => {
                      if (isCCEnabled) {
                        setCCEmails([]);
                        setIsCCEnabled(false);
                      } else {
                        setIsCCEnabled(true);
                      }
                    }}
                  />
                </Col>
              </Row>

              {/* Cc */}
              {isCCEnabled && (
                <Row align="middle">
                  <Col span={1}>
                    <span style={{ color: '#606B85' }}>Cc: </span>
                  </Col>
                  <Col span={23} style={{ paddingLeft: 5 }}>
                    <TagInput
                      fill
                      addOnBlur
                      values={CCEmails}
                      tagProps={{ minimal: true, intent: 'none', style: { borderRadius: 5 } }}
                      className="myCasesEmailTagInput"
                      onChange={(values: any[]) => {
                        setCCEmails(values);
                      }}
                      separator={','}
                      inputProps={{ autoFocus: true }}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>

              {/* Subject */}
              <Row align="middle">
                <Col>
                  <span style={{ color: '#606B85' }}>Subject: </span>
                </Col>
                <Col>
                  <InputGroup
                    fill
                    style={{ boxShadow: 'none', padding: 0, color: 'black', minWidth: 400 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      {
                        setNewEmailSubject(e.target.value);
                        setValidationErrorMessage('');
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div
                    style={{
                      background: 'white',
                      height: isCCEnabled ? 'calc(100vh - 720px)' : 'calc(100vh - 700px)',
                      overflowY: 'auto',
                      padding: 5,
                    }}
                  >
                    <TextArea
                      intent="warning"
                      placeholder="Write your email here"
                      fill
                      autoFocus
                      value={newEmailBody}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setNewEmailBody(e.target.value);
                        setValidationErrorMessage('');
                      }}
                      style={{ height: '100%', boxShadow: 'none', padding: 0 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                {/* Send button / Attach Button */}
                <Col span={8}>
                  <Row>
                    <Col>
                      <Button
                        intent="primary"
                        text="Send"
                        onClick={validateEmail}
                        disabled={isEmailBodyEmpty()}
                        loading={isSendingEmail}
                        style={{ borderRadius: 5 }}
                      />
                    </Col>
                    <Col>
                      <Button
                        icon="paperclip"
                        className="newEmailSectionAttachIcon"
                        disabled
                        minimal
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Error Validation */}
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ color: 'red' }}>{validationErrorMessage}</span>
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesEmailEditorWidget);
